var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('aside', {
    class: _vm.$style.wrapper
  }, [_vm.imageUrl ? _c('img', {
    class: _vm.$style.image,
    attrs: {
      "src": _vm.imageUrl,
      "alt": ""
    }
  }) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };