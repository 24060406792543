// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_OMWNF{display:block;height:calc(100vh - var(--header-h));line-height:0}@media only screen and (max-width:1023.98px){.wrapper_OMWNF{display:none}}.wrapper_OMWNF .image_i5UQB{height:100%;-o-object-fit:cover;object-fit:cover;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_OMWNF",
	"image": "image_i5UQB"
};
module.exports = ___CSS_LOADER_EXPORT___;
